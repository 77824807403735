@font-face {
    font-family: 'Optima';
    src: url('Optima-BlackItalic.eot');
    src: url('Optima-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Optima-BlackItalic.woff2') format('woff2'),
        url('Optima-BlackItalic.woff') format('woff'),
        url('Optima-BlackItalic.ttf') format('truetype'),
        url('Optima-BlackItalic.svg#Optima-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('Optima-Black.eot');
    src: url('Optima-Black.eot?#iefix') format('embedded-opentype'),
        url('Optima-Black.woff2') format('woff2'),
        url('Optima-Black.woff') format('woff'),
        url('Optima-Black.ttf') format('truetype'),
        url('Optima-Black.svg#Optima-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OptimaBold-Demi';
    src: url('OptimaBold-Demi.eot');
    src: url('OptimaBold-Demi.eot?#iefix') format('embedded-opentype'),
        url('OptimaBold-Demi.woff2') format('woff2'),
        url('OptimaBold-Demi.woff') format('woff'),
        url('OptimaBold-Demi.ttf') format('truetype'),
        url('OptimaBold-Demi.svg#OptimaBold-Demi') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Optima';
    src: url('Optima-Bold.eot');
    src: url('Optima-Bold.eot?#iefix') format('embedded-opentype'),
        url('Optima-Bold.woff2') format('woff2'),
        url('Optima-Bold.woff') format('woff'),
        url('Optima-Bold.ttf') format('truetype'),
        url('Optima-Bold.svg#Optima-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OptimaBold-Demi';
    src: url('OptimaBold-DemiItalic.eot');
    src: url('OptimaBold-DemiItalic.eot?#iefix') format('embedded-opentype'),
        url('OptimaBold-DemiItalic.woff2') format('woff2'),
        url('OptimaBold-DemiItalic.woff') format('woff'),
        url('OptimaBold-DemiItalic.ttf') format('truetype'),
        url('OptimaBold-DemiItalic.svg#OptimaBold-DemiItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Optima LT Std';
    src: url('OptimaLTStd.eot');
    src: url('OptimaLTStd.eot?#iefix') format('embedded-opentype'),
        url('OptimaLTStd.woff2') format('woff2'),
        url('OptimaLTStd.woff') format('woff'),
        url('OptimaLTStd.ttf') format('truetype'),
        url('OptimaLTStd.svg#OptimaLTStd') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

