body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, a, span, i, div, strong {
  font-family: 'Karla', sans-serif;
  margin: 0;
}

.loadingPage {
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p5Canvas {
  text-align: center;
}

.wrapperContent {
  // padding-top: 150px;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
  border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	background-color: #19a9ec;
}