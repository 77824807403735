.header {
  background: #fff;
  padding: 55px 100px;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;

  .logo {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-transform: lowercase;
    color: #161412;

    span {
      font-weight: bold;
    }

    .textYellow {
      color: #e1ad7e;
    }

    .textBlue {
      color: #5e8296;
    }
  }

  .menus {
    margin-left: auto;
    width: auto;
    display: inline-flex;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.875px;
      text-transform: uppercase;
      color: #161412;
      margin-left: 43px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .btnPreMint {
      // font-family: "Optima LT Std";
      font-family: Karla;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      // color: #fffdfd;
      color: #161412;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 28px;
      // padding: 8px 20px;
      // background: #161412;
      // border: 1px solid #000000;
      padding: 0;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      // height: 40px;

      span {
        // font-family: "Optima LT Std";
        font-family: Karla;
      }

      &:hover {
        // border-color: #5e8296;
        // background: #5e8296;
      }
    }

    .btnConnect {
      font-family: "Optima LT Std";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #383535;
      background: #dfdfdf;
      padding: 8px 20px;
      height: auto;
      border: none;

      span {
        font-family: "Optima LT Std";
      }

      &:hover {
        border-color: #5e8296;
        background: #5e8296;
      }
    }

    .btnWalletId {
      font-family: "Optima LT Std";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      border: none;
      color: #383535;
      padding: 8px 20px;
      background: #dfdfdf;
      height: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: #5e8296;
        background: #5e8296;
        color: #fff;
      }

      span {
        font-family: "Optima LT Std";
        width: 120px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }

  .btnMenuMobile {
    display: none;
  }
}

.menusMobile {
  margin-left: auto;
  width: auto;

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.875px;
    text-transform: uppercase;
    color: #161412;
    border-bottom: 1px solid #ddd;
    width: 100%;
    text-align: left;
    display: flex;
    padding: 10px 0;
  }

  > div {
    &:last-child {
      a {
        border: none;
      }
    }
  }

  .btnWalletId {
    font-family: "Optima LT Std";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    border: none;
    color: #383535;
    padding: 8px 20px;
    background: #dfdfdf;
    height: auto;
    margin-top: 20px;

    &:hover {
      border-color: #5e8296;
      background: #5e8296;
      color: #fff;
    }

    span {
      font-family: "Optima LT Std";
    }
  }

  .btnPreMint {
    // font-family: "Optima LT Std";
    font-family: Karla;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    // color: #fffdfd;
    // padding: 8px 20px;
    // background: #161412;
    // border: 1px solid #000000;
    padding: 0;
    color: #161412;
    text-transform: uppercase;
    // color: #161412;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // height: 40px;
    margin-top: 15px;

    span {
      // font-family: "Optima LT Std";
      font-family: Karla;
    }

    &:hover {
      background: #5e8296;
    }
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .header {
    .logo {
      font-size: 14px;
    }

    .menus {
      display: none;
    }

    .btnMenuMobile {
      display: inline-flex;
      margin-left: auto;
      font-size: 20px;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
